import React from 'react'

const CloudConsulting = (props) => {
    return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 512 512" {...props}>
                <g>
                    <g>
                        <path d="m423,121c-22.5-64.8-89-109.1-166-109.1-77-5.32907e-15-143.5,44.3-166,109.1-47.1,15.2-79,55.5-79,101.4 0,59.5 53.7,108 119.7,108 11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4-43.5,0-78.9-30.1-78.9-67.1 0-30.4 24.2-57.1 58.9-64.8 7.5-1.7 13.4-7.4 15.3-14.9 13.4-52.2 68-90 129.9-90s116.5,37.9 129.9,90c1.9,7.4 7.8,13.2 15.3,14.9 34.7,7.7 58.9,34.4 58.9,64.8 0,37-35.4,67.1-78.9,67.1-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4 66,0 119.7-48.4 119.7-108 0.2-45.9-31.7-86.2-78.8-101.4z" />
                        <path d="m356.2,399.7c-11.3,0-20.4,9.1-20.4,20.4v41h-157.6v-41c0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4v61.4c0,11.3 9.1,20.4 20.4,20.4h198.5c11.3,0 20.4-9.1 20.4-20.4v-61.4c5.68434e-14-11.2-9.2-20.4-20.5-20.4z" />
                        <path d="m257,213.9c-11.3,0-20.4,9.1-20.4,20.4v111.7l-20.8-19.5c-8.2-7.7-21.2-7.3-28.9,0.9-7.7,8.2-7.3,21.2 0.9,28.9l55.2,51.7c9.2,9.2 19.2,8.7 27.9,0l55.2-51.7c8.2-7.7 8.7-20.6 0.9-28.9-7.7-8.2-20.6-8.6-28.9-0.9l-20.7,19.5v-111.6c0-11.3-9.1-20.5-20.4-20.5z" />
                    </g>
                </g>
            </svg>
    )
}

export default CloudConsulting