import React from 'react';


const Searching = () => {
    return (
        <div className="find">

            <div className='box'>
                <h4>All Courses</h4>
            </div>

            <section className='mx-5 Search'>
                <input placeholder='Search.....'></input>
            </section>
        </div>
    )
}

export default Searching;