import React from 'react'

const CloudHosting = (props) => {
  return (
    <div>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" {...props}>
<g id="_x35_">
	<path d="M82.833,52.706c-1.307,1.312-3.426,1.312-4.733,0c-1.306-1.31-1.306-3.437,0-4.747
		c1.307-1.312,3.426-1.312,4.733,0C84.14,49.269,84.14,51.397,82.833,52.706z M75.768,40.037c-8.281,0-15.015,6.758-15.015,15.055
		c0,0.977,0.098,1.938,0.277,2.866L46.585,72.452v8.704h16.09v-4.079h4.079V72.99h3.355l3.07-3.07
		c0.839,0.155,1.702,0.228,2.589,0.228c8.281,0,15.015-6.75,15.015-15.055C90.782,46.795,84.049,40.037,75.768,40.037z
		 M69.099,70.547h-4.788v4.088h-4.079v4.079H49.028v-5.252l12.784-12.824c0.594,1.506,1.433,2.899,2.459,4.12l-8.94,8.94
		l1.148,1.148l8.9-8.9c1.441,1.4,3.167,2.508,5.089,3.233L69.099,70.547z M75.768,66.891c-6.481,0-11.758-5.293-11.758-11.798
		s5.276-11.798,11.758-11.798c6.481,0,11.758,5.293,11.758,11.798S82.249,66.891,75.768,66.891z"/>
	<path id="icon_3_" d="M96.718,96.527H62.052v-6.514h34.666c13.658,0,24.768-11.302,24.768-25.195
		c0-9.124-4.852-17.55-12.662-21.991l-0.989-0.562l-0.423-1.055c-1.816-4.519-4.785-8.469-8.586-11.424
		c-4.714-3.66-10.343-5.596-16.279-5.596c-6.967,0-13.575,2.71-18.607,7.63l-1.439,1.407l-1.902-0.658
		c-1.659-0.573-3.383-0.864-5.122-0.864c-8.702,0-15.852,7.194-15.938,16.036l-0.016,1.624l-1.307,0.965
		c-2.052,1.514-3.812,3.371-5.232,5.519l-5.433-3.593c1.547-2.341,3.408-4.418,5.545-6.19c0.932-11.651,10.657-20.876,22.381-20.876
		c1.802,0,3.589,0.219,5.336,0.654c6.054-5.281,13.701-8.169,21.735-8.169c7.395,0,14.407,2.409,20.275,6.966
		c4.418,3.434,7.925,7.946,10.196,13.1C122.288,43.471,128,53.741,128,64.819C128,82.303,113.967,96.527,96.718,96.527z
		 M26.054,75.281c14.392,0,26.058-3.575,26.058-7.99c0-4.412-11.665-7.987-26.058-7.987C11.662,59.305,0,62.88,0,67.292
		C0,71.706,11.662,75.281,26.054,75.281z M26.054,79.556C11.662,79.556,0,75.981,0,71.569v7.987c0,4.415,11.662,7.99,26.054,7.99
		c14.392,0,26.058-3.575,26.058-7.99v-7.987C52.112,75.981,40.447,79.556,26.054,79.556z M26.054,91.824
		C11.662,91.824,0,88.246,0,83.834v7.99c0,4.412,11.662,7.99,26.054,7.99c14.392,0,26.058-3.578,26.058-7.99v-7.99
		C52.112,88.246,40.447,91.824,26.054,91.824z M26.054,103.936C11.662,103.936,0,100.357,0,95.946v7.99
		c0,4.412,11.662,7.99,26.054,7.99c14.392,0,26.058-3.578,26.058-7.99v-7.99C52.112,100.357,40.447,103.936,26.054,103.936z"/>
</g>
<g id="Layer_1">
</g>
</svg>
    </div>
  )
}

export default CloudHosting