import React from 'react'

const CloudManage = (props) => {
  return (
    <div>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" {...props}
	 viewBox="0 0 512 512" xmlSpace="preserve">
<g>
	<g>
		<g>
			<path d="M479.912,237.819h-69.228L388.6,216.186c-1.946-1.906-4.561-2.973-7.285-2.973h-3.65l-28.045-49.871
				c22.655-2.935,40.443-22.054,40.773-45.145c0-0.013,0-0.024,0-0.036c0.006-0.628,0.006-1.262-0.006-1.9
				c-0.245-11.76-4.24-22.899-11.551-32.211c-7.875-10.037-19.005-17.023-31.318-19.666c-3.099-0.673-6.309-1.068-9.548-1.177
				c-4.742-0.162-9.44,0.303-13.996,1.351c-3.623-29.202-24.587-53.975-53.447-61.922c-11.201-3.092-23.26-3.465-34.595-1.111
				c-21.731,4.504-40.033,18.456-50.205,37.914c-15.876,0.34-30.808,6.623-42.176,17.778
				c-11.676,11.458-18.285,26.766-18.612,43.104c-0.332,16.617,5.958,32.372,17.714,44.364c6.944,7.084,15.491,12.385,24.782,15.579
				l-43.612,77.554h-14.007l-22.084-21.633c-1.946-1.906-4.561-2.973-7.285-2.973H32.088c-5.75,0-10.411,4.662-10.411,10.411v99.082
				c0,14.425,11.736,26.162,26.161,26.162h115.454c14.425,0,26.161-11.736,26.161-26.162V248.23c0-5.749-4.661-10.411-10.411-10.411
				h-31.334l41.655-74.075h136.596l27.818,49.469h-20.819c-5.75,0-10.411,4.662-10.411,10.411v99.082
				c0,14.425,11.736,26.162,26.161,26.162h115.452c14.425,0,26.161-11.736,26.161-26.162v-74.474
				C490.323,242.48,485.662,237.819,479.912,237.819z M168.633,258.64v64.064c0,2.945-2.395,5.341-5.34,5.341H47.84
				c-2.944,0-5.34-2.396-5.34-5.341v-88.671h33.696l22.084,21.633c1.946,1.906,4.561,2.973,7.285,2.973H168.633z M331.908,142.922
				c-0.01,0-0.021,0-0.032,0H188.031c-11.577,0-22.412-4.549-30.51-12.811c-7.807-7.964-11.985-18.395-11.765-29.372
				c0.447-22.328,18.988-40.493,41.333-40.493c1.366,0,2.76,0.07,4.143,0.208c4.611,0.47,8.974-2.182,10.699-6.485
				c6.609-16.481,20.898-28.466,38.23-32.06c8.123-1.688,16.791-1.422,24.831,0.797c22.796,6.276,38.717,27.199,38.717,50.88
				c0,1.717-0.084,3.425-0.25,5.076c-0.392,3.909,1.449,7.705,4.762,9.818c3.313,2.112,7.532,2.181,10.909,0.175
				c5.473-3.247,11.744-4.861,18.146-4.643c1.985,0.067,3.946,0.308,5.849,0.72c7.587,1.628,14.452,5.95,19.332,12.168
				c4.504,5.735,6.963,12.578,7.113,19.783c0.008,0.413,0.008,0.827,0.003,1.237c-0.211,13.787-11.874,25-26.011,25H331.908z
				 M469.502,322.704c0,2.945-2.395,5.341-5.34,5.341H348.709c-2.944,0-5.34-2.396-5.34-5.341v-88.671h33.696l22.084,21.633
				c1.946,1.906,4.561,2.973,7.285,2.973h63.067V322.704z"/>
			<path d="M331.138,400.952h-65.024c0.024-0.286,0.044-0.576,0.044-0.868V252.792c0-5.749-4.661-10.411-10.411-10.411
				s-10.411,4.662-10.411,10.411v131.925l-5.51-5.399c-1.946-1.906-4.561-2.973-7.285-2.973h-48.357
				c-5.75,0-10.411,4.662-10.411,10.411v99.083c0,14.425,11.736,26.161,26.161,26.161h115.453c14.425,0,26.161-11.735,26.161-26.161
				v-74.475C341.549,405.614,336.888,400.952,331.138,400.952z M320.729,485.837h-0.001c0,2.944-2.396,5.34-5.34,5.34H199.936
				c-2.944,0-5.34-2.396-5.34-5.34v-88.672h33.696l22.084,21.633c1.946,1.906,4.561,2.973,7.285,2.973h63.067V485.837z"/>
			<path d="M255.748,216.335c5.75,0,10.411-4.662,10.411-10.411v-2.082c0-5.749-4.661-10.411-10.411-10.411
				s-10.411,4.662-10.411,10.411v2.082C245.338,211.674,249.999,216.335,255.748,216.335z"/>
		</g>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

    </div>
  )
}

export default CloudManage